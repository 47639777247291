import React from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import Script from "next/script";
import { List } from "phosphor-react";

import { URI_LOGIN } from "constants/urls";
import classNames from "classnames";
import { ForaLogo } from "./common/v2/ForaLogo";

type HeaderProps = {
    showNavbar: () => void;
    // Used to make Aside compatible with LayoutV2. Once we've fully deprecated Layout in favor of LayoutV2, we should apply this props changes permanently and remove this prop.
    layoutV2?: boolean;
};

export default function Header({ showNavbar, layoutV2 = false }: HeaderProps) {
    const router = useRouter();
    const { data: session } = useSession();
    const isImpersonating = session?.isImpersonating;

    return router.pathname !== URI_LOGIN ? (
        <>
            <div className="lg:hidden min-h-[4rem]">
                <header
                    id={"mobileHeader"}
                    className={classNames(
                        !layoutV2 && "fixed lg:static top-0 left-0 right-0",
                        "border-b border-lightGreyBorder lg:border-none bg-background z-[14]"
                    )}
                >
                    <div className="flex-shrink-0 flex justify-between min-h-[4rem] max-w-[790px] pr-[1.6rem] md:pr-[2.1rem] lg:hidden">
                        <div className="flex">
                            <button
                                className="px-4 text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                                onClick={showNavbar}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <List className="text-3xl flex items-center" aria-hidden="true" />
                            </button>

                            <div className="flex items-center pl-4">
                                <ForaLogo
                                    className="mx-auto h-5"
                                    onClick={() => router.push("/")}
                                />
                            </div>
                        </div>
                        {/* Logout button for User profile page was removed after redesign 12.01.2023 */}
                        <Script
                            strategy="beforeInteractive"
                            src="https://unpkg.com/flowbite@1.4.1/dist/datepicker.js"
                        />
                    </div>
                </header>
            </div>
            {!!isImpersonating && !layoutV2 && <div className="lg:pt-[64px]" />}
        </>
    ) : null;
}
